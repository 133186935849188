import { PAYMENT_PLAN_TYPE, schedule_types_ids } from "constants/schedule";

// then: breakdown this file and move to src/features/Listings/constants folder
export const offering_pages_form_keys = {
  course_end_magnitude: "course_end_magnitude",
  payment_plan_type: "payment_plan_type",
  price_per_head: "price_per_head",
  price_international: "price_international",
  course_end_choice: "course_end_choice",
  start_date: "start_date",
  is_international_price_allowed: "is_international_price_allowed",
  highlights: "highlights",
  requirements: "requirements",
  universal_update: "universal_update",
  subscription_config: {
    key: "subscription_config",
    subscription_unit: "subscription_config.subscription_unit",
    is_auto_debit_active: "subscription_config.is_auto_debit_active",
    subscription_duration: "subscription_config.subscription_duration",
  },
};

export const payment_type_options = [
  {
    value: PAYMENT_PLAN_TYPE.ONE_TIME_PAYMENT,
    label: "One-time purchase",
  },
  {
    value: PAYMENT_PLAN_TYPE.SUBSCRIPTIONS,
    label: "Subscription (recurring payments)",
  },
];

export const max_domestic_price = 1000000; // 10 lakhs
export const max_international_price = 100000; // 1 lakh
export const DEFAULT_SUBS_DURATION = 1;
export const INR = "INR";
export const USD = "USD";

export const no_schedule_course_end_choices_values = {
  days: 3,
  weeks: 1,
  months: 2,
};

export const no_schedule_course_end_choices = [
  { value: no_schedule_course_end_choices_values.days, label: "Days" },
  { value: no_schedule_course_end_choices_values.weeks, label: "Weeks" },
  { value: no_schedule_course_end_choices_values.months, label: "Months" },
];

export const listing_customer_country_code = "customer_country_code";

export const mode_options = {
  ONLINE: { label: "Online", id: 0, value: "online" },
  OFFLINE: { label: "Offline", id: 1, value: "offline" },
};

// @dev offering keys which when updated, new data is to be fetched and stored in redux
export const linked_keys = [
  "duration",
  "price_per_head",
  "availability_choice",
];
export const listing_miscellaneous_data_types = {
  requirements: 1,
  highlights: 2,
  reviews: 3,
  invoice_details: 5,
};

export const offering_availability_statuses = {
  draft: 0,
  live: 1,
  sold_out: 2,
  expired: 3,
  deleted: 5,
};

export const offering_status_labels = {
  [offering_availability_statuses.draft]: "Draft",
  [offering_availability_statuses.live]: "Live",
  [offering_availability_statuses.sold_out]: "In Waitlist",
  [offering_availability_statuses.expired]: "Expired",
  [offering_availability_statuses.deleted]: "Deleted",
};

// offering types on which auto debit can be enabled
export const auto_debit_allowed_offering_types = [
  schedule_types_ids.rolling_class,
  schedule_types_ids.one_on_one_class,
  schedule_types_ids.group_class,
  schedule_types_ids.no_schedule,
  schedule_types_ids.whatsapp,
  schedule_types_ids.branded_community,
  schedule_types_ids.telegram,
];

export const listing_apis = {
  get_listing_details: "host/listing/details",
  get_upsell_listings: "host/upsell",
  create_upsell_listing: "host/upsell/map/update",
};

/**
 * const to access list of listings/offerings in redux\
 * we store these in redux on the basis of status
 */
export const listings_redux_status_wise_keys = {
  [offering_availability_statuses.live]: "live",
  [offering_availability_statuses.draft]: "draft",
  [offering_availability_statuses.expired]: "past",
  [offering_availability_statuses.sold_out]: "soldout",
};
